@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/scss/variables";

body {
  background: $pt-app-background-color;
  width: 100%;
  height: 100%;
}

//body {
//  margin: 0;
//  padding: 0;
//  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//    sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}
//
//code {
//  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//    monospace;
//}
